import React from "react";
import { ResponsiveRadar } from "@nivo/radar";


// make sure parent container have a defined height when using responsive component,
// otherwise height will be 0 and no chart will be rendered.
// website examples showcase many properties, you'll often use just a few of them.
const Radar = ({ data, keys, labels,zoneColor }) => {
  return (
    <ResponsiveRadar
      data={data}
      keys={keys}
      indexBy="critere"
      maxValue="5"
      margin={{
        top: 70,
        right: 80,
        bottom: 40,
        left: 80,
      }}
      curve="catmullRomClosed"
      borderWidth={2}
      borderColor="inherit"
      gridLevels={1}
      gridShape="linear"
      gridLabelOffset={5}
      gridLabel={({ id, anchor }) => (
        <text
          textAnchor={anchor}
          style={{
            fontFamily: "sans-serif",
            fontSize: "11px",
            fill: "rgb(51, 51, 51)",
          }}
        >
          {labels[id] || id}
        </text>
      )}
      enableDots={true}
      dotSize={4}
      dotColor="inherit"
      dotBorderWidth={0}
      dotBorderColor="#ffffff"
      enableDotLabel={false}
      dotLabel="value"
      dotLabelYOffset={-12}
      colors={[zoneColor || "#FF00FF", "rgba(0,0,0,0.5)"]}
      colorBy="key"
      fillOpacity={0.8}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          translateX: -150,
          translateY: -70,
          itemWidth: 80,
          itemHeight: 20,
          itemTextColor: "#999",
          symbolSize: 12,
          symbolShape: "circle",
          effects: [
            {
              on: "hover",
              style: {
                itemTextColor: "#000",
              },
            },
          ],
        },
      ]}
    />
  );
};

export default Radar;
