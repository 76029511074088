import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core";

export default () => (
  <Accordion  allowMultiple>
    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le critère de l’âge
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">

        <Stack spacing={5}>
          <Text>
            Les populations âgées (75 ans et plus) font parties des générations
            les plus susceptibles d’avoir des besoins liés à leur état de santé.
            Bien que représentant une petite part de la population (9% à
            l’échelle de la France métropolitaine), elles sont en moyenne plus
            souvent appelées à avoir régulièrement recours aux services de
            santé. Toutefois, la présence d’une population âgée n’est pas
            forcément synonyme de forte consommation de prestations de santé.
            Nombre d’entre elles renoncent à bénéficier d’un suivi médical,
            faute de médecin traitant, ou d’être suffisamment mobiles pour
            consulter.
          </Text>
          <Text>
            La présence d’enfants (moins de 15 ans) dans un territoire traduit
            la présence de familles. Même si leurs besoins individuels de soins
            sont, en moyenne moins importants que ceux des personnes âgées, leur
            part dans la population totale (en France métropolitaine la
            population des familles représente 55% de la population totale)
            oblige le système de soins à leur apporter une réponse adaptée en
            matière de santé dans un territoire. Elle peut aussi se traduire
            dans de nombreux territoires, dans le cadre du parcours de soins
            coordonnés, par des difficultés d’intégrer la patientèle d’un
            praticien à proximité de chez soi, pour des populations qui beaucoup
            plus souvent que les autres, s’inscrivent dans des parcours
            résidentiels (30 à 40% de la population âgée de moins de 40 ans
            change de commune de résidence tous les cinq ans).
          </Text>
        </Stack>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le critère de la catégorie socio-professionnelle (CSP)
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          Le ressenti par rapport à son état de santé est différent d’une CSP à
          l’autre. En règle générale, les ouvriers comme les employés, notamment
          ceux âgés de 45 ans et plus expriment plus souvent que les autres
          populations (dans le cadre d’enquêtes) un état de santé ressenti/perçu
          plus dégradé, sans pour autant que cela se traduise par une
          consommation de prestations de santé plus élevée. Si ce constat d’un
          moindre accès aux soins peut en partie s’expliquer par des ressources
          moins élevées, il est aussi culturel et social. En effet, la CSP comme
          le niveau de formation (les deux étant fortement corrélés) ont un
          impact certain, notamment en matière de prévention et de sensibilité
          aux informations dans le domaine de la santé. Pour ces CSP, plus que
          pour les autres, le renoncement aux services de santé peut être
          important.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le critère de la pauvreté monétaire
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          Les ressources financières d’un ménage ont un impact direct sur ses
          capacités à aller vers le système de santé. Le renoncement aux soins
          est souvent lié au manque de moyens financiers pour les populations à
          très faible niveau de vie, et de surcroît pour les personnes vivant
          dans un ménage situé sous le seuil de pauvreté, (proportionnellement
          plus souvent des familles monoparentales ou nombreuses mais aussi des
          personnes seules), l’accès aux services de santé est difficile. Le
          renoncement y est plus important que pour le reste de la population.
        </Text>
      </AccordionPanel>
    </AccordionItem>

    <AccordionItem>
      <AccordionHeader>
        <Box flex="1" textAlign="left">
          Le critère de la « mise à l’écart de l’emploi »
        </Box>
        <AccordionIcon />
      </AccordionHeader>
      <AccordionPanel pb={4} fontSize="sm">
        <Text>
          Ne pas ou ne plus avoir d’emploi, participe à l’isolement social et
          physique des individus, à la perte de repères et au déclassement.
          C’est un facteur de malêtre, de repli sur soi et de renoncement. Ces
          situations, qui pour nombre d’entre elles peuvent être subies, de
          rupture ou d’éloignement vis-à-vis de l’emploi sont particulièrement
          discriminantes pour les adultes âgés de 45 ans et plus, pour lesquels
          cette situation peut être plus souvent associée à une
          «disqualification sociale», à un état de santé moins satisfaisant,
          sans pour autant que leur consommation de prestations de soins s’en
          ressente. Les jeunes adultes peuvent connaître également des
          difficultés d’accès à l’autonomie ; celle-ci passant généralement par
          l’emploi. Un certain nombre d’entre eux (24% des 20-29 ans en France
          métropolitaine) s’avère être aujourd’hui ni en emploi ni en études et
          donc potentiellement en difficultés d’insertion. Des études récentes
          montrent les liens étroits entre ces situations et un état de santé
          dégradé : une partie non négligeable de ces « jeunes ni ni » ne
          peuvent travailler du fait de leur état de santé6. Par ailleurs, si
          les jeunes se disent globalement plutôt en bonne santé, les disparités
          existantes sur la perception de leur état de santé apparaissent très
          souvent liées à leur situation vis-à-vis de l’emploi.
        </Text>
      </AccordionPanel>
    </AccordionItem>
  </Accordion>
);
