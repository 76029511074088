import React from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  useDisclosure,
  Text,
  Stack,
} from "@chakra-ui/core";


function DisplayModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button onClick={onOpen} size="sm">
        Comprendre les valeurs des indices
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Comprendre les valeurs des indices</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5} fontSize="sm">
              <Text>
                Pour chacun des 7 indicateurs composant l’indice de besoins
                potentiels en santé, une note de 0 à 5 est attribuée à chaque
                territoire étudié. Cette note est arrêtée en fonction de la
                distribution observée pour toutes les communes de plus de 2 000
                habitants, les arrondissements et les départements de France.
              </Text>
              <Text>
                La note de 0 correspond aux territoires où l’indicateur a une
                valeur très faible au regard des autres territoires ; celle de
                5, au contraire, révèle un besoin potentiellement nettement plus
                marqué qu’ailleurs.
              </Text>
              <Text>
                Le total des 7 notes obtenues permet de disposer d’un indice
                synthétique permettant d’appréhender l’importance des besoins
                théoriques ou potentiels, qui souvent ne s’expriment pas en tant
                que tels, en matière de santé dans un territoire.
              </Text>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button variantColor="blue" mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
export default DisplayModal;
