import React, { useState, useEffect } from "react"
import { Flex, Box, Text, Image, Stack, Grid } from "@chakra-ui/core"
import Radar from "./radar"

import {
  preparerDatasetBesoins,
  preparerDatasetRupture,
} from "../fragilites-acces-numerique/appCore"

import { preparerDatasetEnfants } from "../fragilite-des-enfants/appCore"
import { preparerDatasetPA } from "../fragilites-des-personnes-agees/appCore"
import { preparerDatasetSante } from "../sante/appCore"
import {
  preparerDatasetBudget,
  preparerDatasetChomage,
} from "../fragilite-economique/appCore"

import imgbarometrespa from "../../images/fragilite-pa.svg"
import imgbarometresante from "../../images/sante.svg"
import imgFragiliteEnfant from "../../images/fragilite-enfant.svg"
import imgFragiliteNumerique from "../../images/fragilite-numerique.svg"
import imgFragiliteEconomique from "../../images/fragilite-economique2.svg"

/**
 * un peu de layout
 * @param {*} param0
 */
function Card({ children }) {
  return (
    <Flex flexDirection="column" my={25}>
      {children}
    </Flex>
  )
}
function CardHeader({ children }) {
  return <Box mb={5}>{children}</Box>
}

function BarometreSante({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
    maxValueForRadar,
  } = preparerDatasetSante(codzone)

  return (
    <Card>
      <CardHeader>
        <Stack isInline>
          <Image src={imgbarometresante} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Indice de besoins potentiels en santé = {datum.note}
            </Text>
            <Text fontSize="md">Référence nationale = 17.5</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          keys={[territoire.nom, "France Métropolitaine"]}
          labels={labels}
          zoneColor={zoneColor}
        />
      </Box>
    </Card>
  )
}

function BarometrePA({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
    maxValueForRadar,
  } = preparerDatasetPA(codzone)

  return (
    <Card>
      <CardHeader>
        <Stack isInline>
          <Image src={imgbarometrespa} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Part des personnes âgées fragiles ={" "}
              {Math.floor(datum.taux * 10) / 10} %
            </Text>
            <Text fontSize="md">Référence nationale = 17.3</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          maxValue={maxValueForRadar * 1.1}
          keys={["France Métropolitaine", territoire.nom]}
          labels={labels}
          zoneColor={zoneColor}
          colors={["rgba(0,0,0,0.5)", zoneColor || "#FF00FF"]}
        />
      </Box>
    </Card>
  )
}

function BarometreEnfants({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
    maxValueForRadar,
  } = preparerDatasetEnfants(codzone)

  return (
    <Card>
      <CardHeader>
        <Stack isInline>
          <Image src={imgFragiliteEnfant} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Taux de fragilité des enfants = {Math.floor(datum.taux * 10) / 10}
              %
            </Text>
            <Text fontSize="md">Référence nationale = 13%</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          maxValue={maxValueForRadar * 1.1}
          keys={["France Métropolitaine", territoire.nom]}
          labels={labels}
          zoneColor={zoneColor}
          colors={["rgba(0,0,0,0.5)", zoneColor || "#FF00FF"]}
        />
      </Box>
    </Card>
  )
}

function BarometreNumeriqueBesoins({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetBesoins(codzone)

  return (
    <Card>
      <CardHeader>
        <Stack isInline>
          <Image src={imgFragiliteNumerique} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Indice de besoins numériques pour l’accès aux droits ={" "}
              {Math.floor(datum.note * 1) / 1}
            </Text>
            <Text fontSize="md">Référence nationale = 9</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          keys={[territoire.nom, "France Métropolitaine"]}
          labels={labels}
          zoneColor={zoneColor}
          maxValue={5}
        />
      </Box>
    </Card>
  )
}

function BarometreNumeriqueRupture({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetRupture(codzone)

  return (
    <Card flexDirection="column" mt={5}>
      <CardHeader>
        <Stack isInline>
          <Image src={imgFragiliteNumerique} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Indice de rupture face au numérique ={" "}
              {Math.floor(datum.note * 1) / 1}
            </Text>
            <Text fontSize="md">Référence nationale = 15</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          keys={[territoire.nom, "France Métropolitaine"]}
          labels={labels}
          zoneColor={zoneColor}
          maxValue={5}
        />
      </Box>
    </Card>
  )
}

function BarometreEconomiqueBudget({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetBudget(codzone)

  return (
    <Card flexDirection="column" mt={5}>
      <CardHeader>
        <Stack isInline>
          <Image src={imgFragiliteEconomique} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Indice de l’impact de la crise économique sur le budget des
              ménages = {Math.floor(datum.note * 1) / 1}
            </Text>
            <Text fontSize="md">Référence nationale = 12</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          keys={[territoire.nom, "France Métropolitaine"]}
          labels={labels}
          zoneColor={zoneColor}
          maxValue={5}
        />
      </Box>
    </Card>
  )
}

function BarometreEconomiqueChomage({ codzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetChomage(codzone)

  return (
    <Card flexDirection="column" mt={5}>
      <CardHeader>
        <Stack isInline>
          <Image src={imgFragiliteEconomique} size="60px" />
          <Box ml={1}>
            <Text fontSize="lg" fontWeight="600">
              Indice de l’impact de la crise économique sur l’emploi={" "}
              {Math.floor(datum.note * 1) / 1}
            </Text>
            <Text fontSize="md">Référence nationale = 21</Text>
          </Box>
        </Stack>
      </CardHeader>
      <Box width="400px" height="250px">
        <Radar
          data={dataForRadar}
          keys={[territoire.nom, "France Métropolitaine"]}
          labels={labels}
          zoneColor={zoneColor}
          maxValue={5}
        />
      </Box>
    </Card>
  )
}

export default function AppCore({ codzone }) {
  if (!codzone) {
    return null
  }
  return (
    <>
      {/* <Flex flexGrow="1" flexWrap="wrap" justifyContent="space-around"> */}
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <BarometreEnfants codzone={codzone} />
        <BarometrePA codzone={codzone} />
        <BarometreEconomiqueBudget codzone={codzone} />
        <BarometreEconomiqueChomage codzone={codzone} />
        <BarometreNumeriqueRupture codzone={codzone} />
        <BarometreNumeriqueBesoins codzone={codzone} />

        <BarometreSante codzone={codzone} />
      </Grid>
      {/* </Flex> */}
    </>
  )
}
