import React from "react"
import { ResponsiveRadar } from "@nivo/radar"

// make sure parent container have a defined height when using responsive component,
// otherwise height will be 0 and no chart will be rendered.
// website examples showcase many properties, you'll often use just a few of them.
const Radar = ({ data, keys, labels, zoneColor, maxValue, colors }) => {
  return (
    <ResponsiveRadar
      data={data}
      keys={keys}
      indexBy="critere"
      maxValue={maxValue}
      margin={{
        top: 15,
        right: 0,
        bottom: 15,
        left: 0,
      }}
      curve="catmullRomClosed"
      borderWidth={2}
      borderColor="inherit"
      gridLevels={1}
      gridShape="linear"
      gridLabelOffset={5}
      gridLabel={({ id, anchor }) => (
        <text
          textAnchor={anchor}
          style={{
            fontFamily: "sans-serif",
            fontSize: "9px",
            fill: "rgb(51, 51, 51)",
          }}
        >
          {labels[id] || id}
        </text>
      )}
      enableDots={true}
      dotSize={4}
      dotColor="inherit"
      dotBorderWidth={0}
      dotBorderColor="#ffffff"
      enableDotLabel={false}
      dotLabel="value"
      dotLabelYOffset={-12}
      colors={colors || [zoneColor || "#FF00FF", "rgba(0,0,0,0.2)"]}
      colorBy="key"
      fillOpacity={0.8}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      isInteractive={true}
      // legends={[
      //   {
      //     anchor: "bottom-left",
      //     direction: "row",
      //     translateX: -0,
      //     translateY: -30,
      //     itemWidth: 170,
      //     itemHeight: 15,
      //     itemTextColor: "#999",
      //     symbolSize: 12,
      //     symbolShape: "circle",
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemTextColor: "#000",
      //         },
      //       },
      //     ],
      //   },
      // ]}
    />
  )
}

export default Radar
