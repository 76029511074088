import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            L'âge
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Stack spacing={5}>
            <Text>
              Ce critère permet de prendre en compte les personnes âgées pour
              lesquelles l'accès au numérique est moins répandu au regard du
              reste de la population.
            </Text>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le niveau de formation
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            L'étude du Crédoc montre que les personnes ayant au plus un BEPC ou
            brevet des collèges sont les ménages qui ont le plus de difficultés
            dans l’usage autonome du web et de l’accès à internet.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le niveau de vie
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Pour les ménages les plus pauvres, les taux d’équipements sont
            beaucoup plus faibles que ceux des ménages aisés. De plus ils
            progressent moins rapidement.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            L’activité professionnelle
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            Le critère d'absence d'emploi joue dans la maîtrise du numérique.
            Beaucoup d’usagers du numérique ont reçu des formations de la part
            de leur employeur. Ces formations les ont aussi accompagnés dans le
            cadre d’un usage personnel.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            L'immigration
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            La prise en considération de l’immigration est liée avant tout à la
            maîtrise de la langue et de l’écrit en français. Pour beaucoup de
            ménages, l’accès aux plateformes numériques est complexifié par ces
            non maîtrises.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
    <Text fontSize="xs" color="grey" mt={4}>
      Les différents critères présentés ci-dessus s'appuient sur l'étude
      annuelle du Crédoc :{" "}
      <Text as="em">
        Enquête sur la diffusion des technologies et de la communication dans la
        société française en 2019.
      </Text>
    </Text>
  </>
)
