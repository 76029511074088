import React from "react"
import { Stack, Text, List, ListItem } from "@chakra-ui/core"

export default function Methodo() {
  return (
    <>
      <Stack spacing={5} fontSize="sm">
        <Text>
          L'indice de rupture face au numérique s'appuie sur
          6 indicateurs distincts calculés sur chacun des territoires observés :
        </Text>
        <List styleType="disc">
          <ListItem>l'âge,</ListItem>
          <ListItem>
            le lieu de vie (
            <Text as="em">
              cet axe n'est pas utilisé dans les données présentées
            </Text>
            ),
          </ListItem>
          <ListItem>le niveau de formation,</ListItem>
          <ListItem>l'activité professionnelle,</ListItem>
          <ListItem>le niveau de vie,</ListItem>
          <ListItem>l'immigation.</ListItem>
        </List>
        <Text>
          Une valeur indiquant la fragilité des habitants est calculée. On
          attribue une note entre 0 et 5 pour chaque composante. Le baromètre
          global correspond à la somme des notes. Plus cet indice est fort et
          plus le territoire est fragile.
        </Text>
        <Text>
          La valeur de référence pour la France métropolitaine vaut 15.
        </Text>
      </Stack>
    </>
  )
}
