import React from "react"
import { Stack, Text, List, ListItem } from "@chakra-ui/core"

export default function Methodo() {
  return (
    <>
      <Stack spacing={5} fontSize="sm">
        <Text>
          L'indice des besoins pour l'accès aux droits s'appuie sur 3
          indicateurs distincts calculés sur chacun des territoires observés :
        </Text>
        <List styleType="disc">
          <ListItem>les revenus issus des prestations,</ListItem>
          <ListItem>le chômage,</ListItem>
          <ListItem>les ménages avec enfants de 6 à 18 ans.</ListItem>
        </List>
        <Text>
          Les trois composantes sont notées de 0 à 5. La note maximale possible
          est donc 15 et marquerait un besoin maximal de la population face au
          numérique.
        </Text>
        <Text>
          La valeur de référence pour la France métropolitaine vaut 9.
        </Text>
      </Stack>
    </>
  )
}
