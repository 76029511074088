import React, { useState, useEffect } from "react"

import { Flex, Text, Select, Link } from "@chakra-ui/core"
import { Box } from "@chakra-ui/core"
import { FaMap } from "react-icons/fa"
import _ from "lodash"

import ModalCriteres from "./ModalCriteres"
import ModalMethodo from "./ModalMethodo"
import Carte from "./carte"
import Radar from "./radar"
import data from "./dataset/data.json"
import territoires from "./dataset/territoires.json"
import Position from "./position"
import startImg from "../../images/37255.jpg"


export function preparerDatasetSante(currentCodzone) {
  

  const palettes = [
    "rgb(243,219,173)",
    "rgb(225,164,51)",
    "rgb(104,195,204)",
    "rgb(0,156,180)",
    "rgb(4,102,134)",
  ]
  const intervalles = [14, 16, 20, 23]

  const labels = {
    d1: "75 ans et +",
    d2: "Moins de 15 ans",
    d3: "Ouvriers 45 ans et +",
    d4: "Employés 45 ans et +",
    d5: "Taux de pauvreté",
    d6: "Inactifs 45-64 ans",
    d7: "Inactifs 20-29 ans",
  }

  const datum = _.find(data, item => item.codzone === currentCodzone)
  const territoire = _.find(
    territoires,
    terr => terr.codzone === currentCodzone
  )
  let dataForRadar = []
  let zoneColor = "#FF00FF"

  if (datum) {
    dataForRadar = _.keys(datum)
      .filter(key => key !== "codzone" && key !== "note")
      .map(key => ({
        critere: `${labels[key]}`,
        [`${territoire.nom}`]: datum[key],
        "France Métropolitaine": 2.5,
      }))
    let intervalle = 0
    while (
      intervalle < intervalles.length &&
      intervalles[intervalle] <= datum.note
    ) {
      intervalle++
    }
    zoneColor = palettes[intervalle]
  }
  
  return {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    
    intervalles,
    labels,
  }
}

function AppCore({
  codzone: currentCodzone,
  setCodzone,
  selectContainer
}) {
  
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
   
    intervalles,
    labels,
  } = preparerDatasetSante(currentCodzone)

  return (
    <>
     <Box ml={10} mr={10} ref={selectContainer}>
         
        {currentCodzone && territoire ? (
          <>
            <Text fontSize="lg" fontWeight="600" mt={5}>
              Indice de besoins potentiels en santé = {datum.note}
            </Text>
            <Text fontSize="md">Référence nationale = 17.5</Text>

            <Flex direction="row" wrap="wrap">
              <Flex minWidth="600px" height="400px">
                <Radar
                  data={dataForRadar}
                  keys={[territoire.nom, "France Métropolitaine"]}
                  labels={labels}
                  zoneColor={zoneColor}
                />
              </Flex>

              <Flex direction="column">
                <Text>En comparaison avec les autres départements</Text>
                <Carte palettes={palettes} intervalles={intervalles} />
              </Flex>
            </Flex>
            <Flex justifyContent="space-between">
              <Flex>
                <Box mr={5}>
                  <ModalMethodo />
                </Box>
                <ModalCriteres />
              </Flex>
              <Flex>
                <Text fontSize="xs">
                  Sources :{" "}
                  <Link href="http://www.le-compas.fr" isExternal>
                    Compas©
                  </Link>
                  , Insee RP et Filosofi 2016
                </Text>
              </Flex>
              <Flex>
                <Position
                  palettes={palettes}
                  intervalles={intervalles}
                  note={datum.note}
                />
              </Flex>
            </Flex>
          </>
        ) : null}
      </Box>
    </>
  )
}

export default AppCore
