import React from "react"
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Stack,
} from "@chakra-ui/core"

export default () => (
  <>
    <Accordion allowMultiple>
      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Les familles avec un enfant de 6/18 ans
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Stack spacing={5}>
            <Text>
              La période du confinement a mis fortement en évidence l’importance
              des accès au numérique pour les enfants et les jeunes en phase
              d’apprentissage.
            </Text>
          </Stack>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            La demande d’emploi
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            La présence des demandeurs d’emploi dans les critères de notre
            baromètre est liée aux procédures de pôle emploi. Depuis quelques
            années, le <Text as="em">pointage</Text> s’effectue numériquement.
          </Text>
        </AccordionPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionHeader>
          <Box flex="1" textAlign="left">
            Le niveau de dépendance aux prestations.
          </Box>
          <AccordionIcon />
        </AccordionHeader>
        <AccordionPanel pb={4} fontSize="sm">
          <Text>
            L’accès aux droits sociaux pour le budget des ménages : il peut
            s’agir des aides au logement, des minima sociaux, des allocations
            familiales, des autres droits financiers.
          </Text>
          <Text>
            Le numérique est nécessaire tant pour avoir accès aux droits ou les
            renouveler que pour prendre connaissance des virements.
          </Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
)
