import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SelectTerr from "../components/barometres/select"
import AppCore from "../components/barometres/appCore"


const Barometres = () => {
  const [codzone, setCodzone] = useState(undefined)
  const selectContainer = useRef(null)
  const headerContainer = useRef(null)

  return (
    <Layout
      title="Tous les baromètres en un coup d'oeil"
      headerContainer={headerContainer}
    //   img={}
    >
      <SEO title="Tous les Baromètres Compas en un coup d'oeil" />
     
      <SelectTerr
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
      <AppCore
        codzone={codzone}
        setCodzone={setCodzone}
        selectContainer={selectContainer}
        headerContainer={headerContainer}
      />
    </Layout>
  )
}

export default Barometres
