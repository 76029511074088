import React, { useState, useEffect } from "react"

import { Flex, Text, Select, Link } from "@chakra-ui/core"
import { Box } from "@chakra-ui/core"
import { FaMap } from "react-icons/fa"
import _ from "lodash"

import ModalCriteres from "./ModalCriteres"
import ModalMethodo from "./ModalMethodo"
import Carte from "./carte"
import Radar from "./radar"
import territoires from "./dataset/territoires.json"
import Position from "./position"

import dataIndicateur1 from "./dataset/data-rupture.json"
import CriteresIndicateur1 from "./Criteres-rupture"
import MethodoIndicateur1 from "./Methodo-rupture"

import dataIndicateur2 from "./dataset/data-besoins.json"
import CriteresIndicateur2 from "./Criteres-besoins"
import MethodoIndicateur2 from "./Methodo-besoins"

function AppCore({ codzone: currentCodzone, indicateur, selectContainer }) {
  return (
    <>
      <Box ml={10} mr={10} ref={selectContainer}>
        {currentCodzone
          ? indicateur === 0 && <Indicateur1 codzone={currentCodzone} />
          : null}
        {currentCodzone
          ? indicateur === 1 && <Indicateur2 codzone={currentCodzone} />
          : null}
      </Box>
    </>
  )
}

/**
 * preparer le dataSet pour l'indicateur Rupture
 * @param {*} currentCodzone
 */
export function preparerDatasetRupture(currentCodzone) {
  const palettes = [
    "rgb(4,102,134)",
    "rgb(0,156,180)",
    "rgb(243,219,173)",
    "rgb(225,164,51)",
  ]
  const intervalles = [12, 15, 16]

  const labels = {
    d1: "Bas niveaux de formation",
    d2: "Population immigrée",
    d3: "Les 15 ans et + sans emploi",
    d4: "Taux de pauvreté",
    d5: "70 ans et +",
  }

  const dataFM = {
    codzone: "France métropolitaine",
    note: 15,
    d1: 3,
    d2: 3,
    d3: 3,
    d4: 3,
    d5: 3,
  }

  const datum = _.find(dataIndicateur1, item => item.codzone === currentCodzone)
  const territoire = _.find(
    territoires,
    terr => terr.codzone === currentCodzone
  )
  let dataForRadar = []
  let maxValueForRadar = undefined
  let zoneColor = "#FF00FF"

  if (datum) {
    dataForRadar = _.keys(datum)
      .filter(key => key !== "codzone" && key !== "note")
      .map(key => {
        const valDep = datum[key]

        if (valDep > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = valDep
        }
        if (dataFM[key] > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = dataFM[key]
        }
        return {
          critere: `${labels[key]}`,
          [`${territoire.nom}`]: valDep,
          "France Métropolitaine": dataFM[key],
        }
      })
    let intervalle = 0
    while (
      intervalle < intervalles.length &&
      intervalles[intervalle] <= datum.note
    ) {
      intervalle++
    }
    zoneColor = palettes[intervalle]
  }

  return {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  }
}

function Indicateur1({ codzone: currentCodzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetRupture(currentCodzone)

  return (
    <>
      <Text fontSize="lg" fontWeight="600" mt={5}>
        Indice de rupture face au numérique = {Math.floor(datum.note * 1) / 1}
      </Text>
      <Text fontSize="md">Référence nationale = 15</Text>

      <Flex direction="row" wrap="wrap">
        <Flex minWidth="600px" height="400px">
          <Radar
            data={dataForRadar}
            keys={[territoire.nom, "France Métropolitaine"]}
            labels={labels}
            zoneColor={zoneColor}
            maxValue={5}
          />
        </Flex>

        <Flex direction="column">
          <Text>En comparaison avec les autres départements</Text>
          <Carte
            palettes={palettes}
            intervalles={intervalles}
            data={dataIndicateur1}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Flex>
          <Box mr={5}>
            <ModalMethodo>
              <MethodoIndicateur1 />
            </ModalMethodo>
          </Box>
          <ModalCriteres>
            <CriteresIndicateur1 />
          </ModalCriteres>
        </Flex>
        <Flex flexDirection={"column"}>
          <Text fontSize="xs">
            Sources :
            <Link href="http://www.le-compas.fr" isExternal>
              Traitements Compas©,
            </Link>
          </Text>
          <Text fontSize={"xs"}> Insee RP 2019, Filosofi 2020</Text>
        </Flex>
        <Flex>
          <Position
            palettes={palettes}
            intervalles={intervalles}
            note={datum.note}
          />
        </Flex>
      </Flex>
    </>
  )
}

/**
 * preparer le jeu de données pour alimenter les composants React
 *
 * @param {*} currentCodzone
 */
export function preparerDatasetBesoins(currentCodzone) {
  const palettes = [
    "rgb(4,102,134)",
    "rgb(0,156,180)",
    "rgb(243,219,173)",
    "rgb(225,164,51)",
  ]
  const intervalles = [7, 9, 11]

  const labels = {
    d1: "Revenus issus des prestations",
    d2: "Ménages avec enfants (6-18 ans)",
    d3: "Chômage",
  }

  const dataFM = {
    codzone: "France métropolitaine",
    note: 9,
    d1: 3,
    d2: 3,
    d3: 3,
  }

  const datum = _.find(dataIndicateur2, item => item.codzone === currentCodzone)
  const territoire = _.find(
    territoires,
    terr => terr.codzone === currentCodzone
  )
  let dataForRadar = []
  let maxValueForRadar = undefined
  let zoneColor = "#FF00FF"

  if (datum) {
    dataForRadar = _.keys(datum)
      .filter(key => key !== "codzone" && key !== "note")
      .map(key => {
        const valDep = datum[key]

        if (valDep > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = valDep
        }
        if (dataFM[key] > maxValueForRadar || !maxValueForRadar) {
          maxValueForRadar = dataFM[key]
        }
        return {
          critere: `${labels[key]}`,
          [`${territoire.nom}`]: valDep,
          "France Métropolitaine": dataFM[key],
        }
      })
    let intervalle = 0
    while (
      intervalle < intervalles.length &&
      intervalles[intervalle] <= datum.note
    ) {
      intervalle++
    }
    zoneColor = palettes[intervalle]
  }
  return {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  }
}

function Indicateur2({ codzone: currentCodzone }) {
  const {
    territoire,
    datum,
    dataForRadar,
    zoneColor,
    palettes,
    intervalles,
    labels,
  } = preparerDatasetBesoins(currentCodzone)

  return (
    <>
      <Text fontSize="lg" fontWeight="600" mt={5}>
        Indice de besoins pour l’accès aux droits ={" "}
        {Math.floor(datum.note * 1) / 1}
      </Text>
      <Text fontSize="md">Référence nationale = 9</Text>

      <Flex direction="row" wrap="wrap">
        <Flex minWidth="600px" height="400px">
          <Radar
            data={dataForRadar}
            keys={[territoire.nom, "France Métropolitaine"]}
            labels={labels}
            zoneColor={zoneColor}
            maxValue={5}
          />
        </Flex>

        <Flex direction="column">
          <Text>En comparaison avec les autres départements</Text>
          <Carte
            palettes={palettes}
            intervalles={intervalles}
            data={dataIndicateur2}
          />
        </Flex>
      </Flex>
      <Flex justifyContent="space-between">
        <Flex>
          <Box mr={5}>
            <ModalMethodo>
              <MethodoIndicateur2 />
            </ModalMethodo>
          </Box>
          <ModalCriteres>
            <CriteresIndicateur2 />
          </ModalCriteres>
        </Flex>
        <Flex>
          <Text fontSize="xs">
            Sources :{" "}
            <Link href="http://www.le-compas.fr" isExternal>
              Traitements Compas©
            </Link>
            , Insee RP 2016
          </Text>
        </Flex>
        <Flex>
          <Position
            palettes={palettes}
            intervalles={intervalles}
            note={datum.note}
          />
        </Flex>
      </Flex>
    </>
  )
}
export default AppCore
